<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer ref="form" #default="{ invalid }">
      <b-form class="auth-login-form mt-2">
        <b-form-group :label="$t('Ismi')" label-for="name">
          <b-form-input
            id="name"
            v-model="form.firstname"
            name="name"
            placeholder=""
          />
        </b-form-group>
        <b-form-group :label="$t('Familiyasi')" label-for="lastname">
          <b-form-input
            id="lastname"
            v-model="form.lastname"
            name="lastname"
            :placeholder="$t('Familiya')"
          />
        </b-form-group>
        <b-form-group :label="$t('Email')" label-for="mail">
          <validation-provider
            #default="{ errors }"
            name="mail"
            rules="required"
          >
            <b-form-input
              id="mail"
              v-model="form.mail"
              :state="errors.length > 3 ? false : null"
              name="mail"
              type="email"
              :placeholder="$t('test@mail.com')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- <b-form-group
          :label="$t('iCloud')"
          label-for="icloud"
        >
          <validation-provider
            #default="{ errors }"
            name="icloud"
            rules="required"
          >
            <b-form-input
              id="icloud"
              v-model="form.icloud"
              type="email"
              :state="errors.length > 3 ? false:null"
              name="icloud"
              :placeholder="$t('test@icloud.com')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group> -->
        <b-form-group :label="$t('Введите телефон')" label-for="v-phone">
          <validation-provider
            #default="{ errors }"
            :name="$t('Введите телефон')"
            rules="required"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text> +998 </b-input-group-prepend>

              <b-form-input
                id="v-phone"
                v-model="form.phone"
                v-mask="'#########'"
                name="v-phone"
                :state="errors.length > 3 ? false : null"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-for="user-new-password"
          :label="$t('Icloud paroli')"
        >
          <validation-provider
            #default="{ errors }"
            name="password"
            vid="password"
            :rules="form.id ? '' : 'required'"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="user-new-password"
                v-model="form.icloud_password"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="icloud parol"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="mail-password" :label="$t('Email paroli')">
          <validation-provider
            #default="{ errors }"
            name="password"
            vid="password"
            :rules="form.id ? '' : 'required'"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="mail-password"
                v-model="form.mail_password"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="Email parol"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { showToast } from "@/utils/toast";
import { required } from "@validations";
import { mapActions } from "vuex";
import { clearObject } from "@/utils";

export default {
  name: "Create",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      form: {
        mail: "",
        icloud: "",
        password: "",
        icloud_password: "",
        mail_password: "",
        phone: "",
        firstname: "",
        lastname: "",
      },
      title: "",
      visible: false,
      required,
      passwordFieldTypeNew: "password",
    };
  },
  computed: {
    itemId() {
      return this.$route.query.id;
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal)
        setTimeout(() => {
          clearObject(this.form);
        }, 200);
    },
  },
  mounted() {
    this.title = this.$t("Добавить значение");

    this.$root.$on("add", (data) => {
      this.visible = true;
    });
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    async save() {
      const valid = await this.validationForm();
      if (valid) {
        this.method(this.form)
          .then((res) => {
            showToast(
              "success",
              this.$t("Успешно сохранено"),
              "CheckCircleIcon"
            );
            this.$emit("onSuccess");
            this.visible = false;
          })
          .catch((err) => {
            if (err.status === 422) {
              this.$refs.form.setErrors(err.data.errors);
            }
            showToast("danger", this.$t("Ошибка"), "XIcon");
          });
      } else {
        showToast("warning", this.$t("Заполните необходимые поля"));
      }
    },
    getCities(cityId) {
      this.fetchCities({ region_id: cityId });
    },
    edit(item) {
      this.form.id = item.id;
      this.form.name_uz = item.name_uz;
      this.form.name_ru = item.name_ru;
      this.visible = true;
    },
    method(data) {
      if (this.form.id) return this.updateItem(data);
      return this.storeItem(data);
    },
    async validationForm() {
      let validated = false;
      await this.$refs.form.validate().then((success) => {
        validated = success;
      });
      return validated;
    },
    ...mapActions({
      storeItem: "resource/storeICloud",
      updateItem: "resource/updateICloud",
    }),
  },
};
</script>

<style scoped></style>
