<template>
  <div>
    <save
      ref="saveForm"
      @onSuccess="getItems"
    />
    <div class="d-flex justify-content-between">
      <!-- <b-tabs>
        <b-tab
          v-for="tab in settingTabs"
          :key="tab.id"
          :title="tab.name"
          @click="onChange(tab.id)"
        />
      </b-tabs> -->
      <b-overlay :show="loadingBtn">
        <b-button
          variant="outline-success"
          class="float-right"
          size="small"
          @click="tableToExcel"
        >
          <img
            src="/images/excel_1.svg"
            alt=""
          >
          Yuklab olish
        </b-button>
      </b-overlay>
    </div>

    <b-overlay :show="loading">
      <good-table-column-search
        v-if="items"
        :items="items.data"
        :columns="columns"
        :total="items.total"
        :page="page"
        :has-show="true"
        model="knox"
        :filter="filterModel"
        @confirm="approveKnox"
        @getItems="getItems"
        @add="$refs.saveForm.visible = true"
        @onPageChange="(p) => (page = p)"
      />

      <good-table-column-search
        v-if="itemsX"
        v-show="false"
        :items="itemsX.data"
        class="tablee"
        :columns="columns"
        :total="itemsX.total"
        :page="page"
        :filter="filterModel"
      />
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import Save from './save.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch,
    Save,
  },
  data() {
    return {
      page: 1,
      loading: false,
      items: [],
      itemsX: [],
      filterModel: {
        per_page: 50,
        relations: 'contract.client',
      },
      loadingBtn: false,

      columns: [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: 'IMEI',
          field: 'imei',
          showField: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Ismi',
          field: 'contract.client.firstname',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Familiyasi',
          field: 'contract.client.lastname',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Telefon',
          field: 'contract.client.phone',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Все',
            filterDropdownItems: [
              { value: 'approved', text: this.$t('Aktiv') },
              { value: 'blocked', text: this.$t('Bloklangan') },
            ],
          },
        },
        {
          label: 'Дата создания',
          field: 'created_at',
          isDate: true,
        },
        {
          label: '',
          field: 'confirm_action',
        },
      ],
      //
      uri: 'data:application/vnd.ms-excel;base64,',
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)))
      },
      format(s, c) {
        return s.replace(/{(\w+)}/g, (m, p) => c[p])
      },
    }
  },
  computed: {},
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'resource/getKnoxes',
      knoxApprove: 'resource/knoxApprove',
    }),
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page }).then(
        res => {
          this.items = res.data
        },
      )
      this.loading = false
    },
    async approveKnox(id) {
      await this.knoxApprove({
        knox_id: id,
      })
        .then(res => {
          showToast('success', this.$t('Tasdiqlandi'))
          this.getItems()
        })
    },
    async getItemsX() {
      this.loadingBtn = true
      await this.getItemsAction({
        per_page: 99999,
        relations: 'contract.client',
        page: 1,
      }).then(res => {
        this.itemsX = res.data
      })
      this.loadingBtn = false
    },
    nameByLocal(nameuz, nameru) {
      if (this.$i18n.locale == 'uz') {
        return nameuz
      }
      return nameru
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id)
              .then(res => {
                showToast('success', this.$t('Успешно удалено'))
                this.getItems()
              })
              .catch(() => {
                showToast('success', this.$t('Успешно удалено'))
              })
          }
        })
    },
    async tableToExcel(table) {
      this.loadingBtn = true
      await this.getItemsX()

      const tableeee = document.querySelector(
        'div.tablee div.vgt-responsive table',
      )
      const secondRow = tableeee.querySelector('thead tr:nth-child(2)')
      if (secondRow) {
        secondRow.remove()
      }
      setTimeout(() => {
        if (!table.nodeType) table = tableeee
        const ctx = {
          worksheet: `KNOX'lar ro'yhati (${this.todayDate})`,
          table: table.innerHTML,
        }
        const link = document.createElement('a')
        link.download = `KNOX'lar ro'yhati (${this.todayDate}).xls`
        link.href = this.uri + this.base64(this.format(this.template, ctx))
        link.click()
        this.loadingBtn = false
      }, 100)
    },
  },
}
</script>

<style scoped></style>
